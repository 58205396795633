<div class="wrapper" id="wrapper">

  <div id="svgPage" class="mobile-hidden"></div>

  <div class="teaserImagePage clip-circle" style="background-image: url('{{ project?.teaserImagePage }}')"></div>

  <div class="page-section">
    <div class="text-block-intro">
      <div class="single-title">{{ project?.title }}</div>
      <div class="single-subtitle">{{ project?.subtitle }}</div>
      <div class="single-description">{{ project?.description_page }}</div>
    </div>
  </div>

  <div class="page-section small">
    <div class="project-title-info-section">{{ project?.title }}</div>
    <div class="project-information">
      <div class="column-page-section">
        <p class="column-header">{{ project?.type }}</p>
        <p class="column-body">Supervised by <br>{{ project?.supervisor }}</p>
      </div>

      <div class="column-page-section" class="mobile-hidden">
        <p class="column-header">Tech</p>
        <p class="column-body" *ngFor="let tool of project?.tools">{{ tool }}</p>
      </div>

      <div class="column-page-section" class="mobile-hidden">
        <p class="column-header">User Research</p>
        <p class="column-body" *ngFor="let study of project?.userStudies">{{ study }}</p>
      </div>

      <div class="column-page-section" class="mobile-hidden">
        <p class="column-header">Publications</p>
        <p class="column-body" *ngFor="let publication of project?.publications">{{ publication.conference }}</p>
      </div>

      <div class="column-page-section" *ngIf="project?.awards" class="mobile-hidden">
        <p class="column-header">Awards</p>
        <p class="column-body" *ngFor="let award of project?.awards">{{ award.award }}</p>
      </div>
    </div>
  </div>


  <div class="page-section text" *ngIf="project?.content && project?.content.length > 0">
    <div class="page-section-inner-flex {{ project?.content[0].flexOrientation }}">
      <div class="text-box">
        <p class="project-title">{{ project?.content[0].subtitle }}</p>
        <p class="single-abstract">{{ project?.content[0].text }}</p>
      </div>
      <div class="content-image" *ngIf="project?.content[0].format !== 'svg'"><img src="{{ project?.content[0].image }}"></div>
      <div class="content-image" *ngIf="project?.content[0].format === 'svg'">
        <div class="svgImage" *ngIf="project?.id === 1" [inlineSVG]="'/assets/images/ripple.svg'"></div>
        <div class="svgImage" *ngIf="project?.id === 3" [inlineSVG]="'/assets/images/knob-shapes.svg'"></div>
      </div>
    </div>
  </div>

  <div class="page-section">
    <div id="project-images-container">
      <div id="project-images">
        <div *ngFor="let image of project.pictures; let i = index" [attr.data-index]='i'>
          <div class="project-image-item" [ngStyle]="{'background-image': 'url(' + image.src + ')'}"></div>
        </div>
        <div class="large-title inline" (click)="resetScrollOffset()">&lt;</div>
      </div>
    </div>
    <div class="large-title">DESIGN<br>PROCESS</div>
  </div>

  <div class="page-section text" *ngIf="project?.content && project?.content.length > 1">
    <div class="page-section-inner-flex {{ project?.content[1].flexOrientation }}">
      <div class="text-box">
        <p class="project-title">{{ project?.content[1].subtitle }}</p>
        <p class="single-abstract">{{ project?.content[1].text }}</p>
      </div>
      <div class="content-image" *ngIf="project?.content[1].format !== 'svg'"><img src="{{ project?.content[1].image }}"></div>
      <div class="content-image" *ngIf="project?.content[1].format === 'svg'">
        <div class="svgImage" *ngIf="project?.id === 3" [inlineSVG]="'/assets/images/performance-study-dynaknob.svg'"></div>
        <div class="svgImage" *ngIf="project?.id === 4" [inlineSVG]="'/assets/images/interaction_techniques.svg'"></div>
      </div>
    </div>
  </div>

  <div class="page-section video" *ngIf="project?.video">
    <div class="video-wrapper">
      <video #video width="100%" height="auto" [muted]="true" class="video" id="video">
          <source src="{{ project?.video }}" type="video/mp4">
      </video>
      <div class="play-button-background" id="play-button-bg"></div>
      <div class="play-button" id="play-button" (click)="playVideo()"><div class="arrow-right"></div></div>
    </div>
  </div>

  <div class="page-section footer" id="page-footer">
    <div id="horizontal-container">
      <div id="horizontal-content" class="yellow">
        <div class="dissemination-item" *ngFor="let publication of this.project?.publications">
          <div class="footer-links">
            <div class="footer-publication-image" (click)="downloadDocument(publication?.url)"><img src="/assets/images/paper-icon.svg"></div>
            <div class="footer-publication-image" *ngIf="publication.video" (click)="downloadDocument(publication?.video)"><img src="/assets/images/video-icon.svg"></div>
          </div>
          <div class="footer-publication-text">
            <p class="publication-title single">{{ publication?.title }}</p>
            <p class="publication-authors single">{{ publication?.authors }}</p>
            <p class="publication-conference single">{{ publication?.conference }} {{ publication?.type }}</p>
          </div>

        </div>
      </div>
    </div>
    <div class="large-title footer">WANT<br>TO LEARN<br>MORE?</div>
  </div>

  <div class="footerBG" id="footerBG">
    <div id="footerSVG"></div>
    <div class="footer-text">
      Learn more about <br><span class="in-text-link" (click)="this.link('/')">the other projects</span><br>
      or <span class="in-text-link" (click)="this.link('about', true)">me</span>.

    </div>
    <div class="social-media-icons">
      <a href="https://www.linkedin.com/in/anke-van-oosterhout-1783359b/" target="_blank">
        <div class="icon linkedin"><img src="/assets/images/linkedin.svg"></div></a>
      <a href="/">
        <div class="icon twitter"><img src="/assets/images/twitter.svg"></div></a>
    </div>

    <div class="footer-nav">
      <svg version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
        viewBox="0 0 229.9 99.2" style="enable-background:new 0 0 229.9 99.2;" xml:space="preserve">
      <polygon (click)="this.navigateTo(-1)" id="arrow-prev" class="footer-nav-arrow" [ngClass]="{ hidden: project?.id === 1 }" points="34.4,49.8 75.3,7 40.9,7 0,49.8 40.9,92.6 75.3,92.6 "/>
      <polygon (click)="this.navigateTo(1)" id="arrow-next" class="footer-nav-arrow" [ngClass]="{ hidden: project?.id === 4 }" points="195.5,49.8 154.6,7 189,7 229.9,49.8 189,92.6 154.6,92.6 "/>
      <polygon class="footer-nav-arrow" [ngClass]="{ hidden: project?.id === 4 || project?.id === 1 }" points="86.9,92.6 110.6,92.6 142,7 118.3,7 "/>
      </svg>
    </div>
  </div>


</div>

<div id="cursor"></div>
