import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';

@Component({
  template: `<app-research></app-research>`
})
export class ResearchPageComponent implements OnInit {


  constructor(private route: ActivatedRoute) {
  }

  ngOnInit(): void {

  }
}
