<div class="wrapper">
  <canvas id="bg"></canvas>
  <div class="navigation left active" id="left-button" (mouseenter)="this.cursorService.showCursorHighlight()" (mouseleave)="this.cursorService.hideCursorHighlight()" (click)="link('research')"><span></span>my research</div>
  <div class="navigation right" id="right-button" (mouseenter)="this.cursorService.showCursorHighlight()" (mouseleave)="this.cursorService.hideCursorHighlight()" (click)="link('about')"><span></span>about me</div>

  <div class="page-section start">
    <div class="designer-text-transition">
      <div class="first-word">
        <div class="word" id="graphic">
          <span class="letter">M</span>
          <span class="letter">Y</span>
          <span class="letter"> R</span>
          <span class="letter">E</span>
          <span class="letter">S</span>
          <span class="letter">E</span>
          <span class="letter">A</span>
          <span class="letter">R</span>
          <span class="letter">C</span>
          <span class="letter">H</span>
        </div>
      </div>
    </div>
  </div>

  <div class="page-section footer" id="page-footer">

  </div>

  <div class="footerBG" id="footerBG">
    <div id="footerSVG"></div>
    <div class="footer-text">
      Learn more about <br><span class="in-text-link" (click)="this.link('/')">my work</span> or <br>
      <span class="in-text-link" (click)="this.link('about')">me</span>.

    </div>
    <div class="social-media-icons">
      <a href="https://www.linkedin.com/in/anke-van-oosterhout-1783359b/" target="_blank">
        <div class="icon linkedin"><img src="/assets/images/linkedin.svg"></div></a>
      <a href="/">
        <div class="icon twitter"><img src="/assets/images/twitter.svg"></div></a>
    </div>
  </div>


  <div id="cursor"></div>
</div>

