import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Resolve, RouterStateSnapshot } from '@angular/router';
import { Observable } from 'rxjs';
import { Data } from '../models/data.model';
import { ContentService } from './content.service';
import { map } from 'rxjs/operators';


@Injectable({
  providedIn: 'root'
})
export class PageResolverService implements Resolve<Data> {

  constructor(private contentService: ContentService) {}

  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<Data> | Promise<Data> | Data {
    const id = route.paramMap.get('id');
    return this.contentService.getData().pipe(map((projects) => projects['projects'].filter(d => d.slug === id)[0]))
  }

}

