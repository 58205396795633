import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { Data, Degree, Publication } from '../models/data.model';

@Injectable()
export class ContentService {

  constructor(private http: HttpClient) {}

  getData(): Observable<Data[]> {
    const url = '/assets/data.json';
    return this.http.get<Data[]>(url);
  }

  getPublications(): Observable<Publication[]> {
    const url = '/assets/publications.json';
    return this.http.get<Publication[]>(url);
  }

  getSkills(): Observable<any> {
    const url = '/assets/skills.json';
    return this.http.get<any>(url);
  }

  getDegrees(): Observable<Degree[]> {
    const url = '/assets/degrees.json';
    return this.http.get<Degree[]>(url);
  }

  getTextAbout(): Observable<any[]> {
    const url = '/assets/text-about.json';
    return this.http.get<any[]>(url);
  }
}

