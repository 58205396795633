import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Resolve, RouterStateSnapshot } from '@angular/router';
import { Observable } from 'rxjs';
import { Data } from '../models/data.model';
import { ContentService } from './content.service';


@Injectable({
  providedIn: 'root'
})
export class ResolverService implements Resolve<Data[]> {

  constructor(private contentService: ContentService) {}

  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<Data[]> | Promise<Data[]> | Data[] {
    return this.contentService.getData();
  }


}

