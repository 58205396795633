import { Component } from '@angular/core';
// import { routerTransition } from './animations/router.animations';
import { ConfigService } from './services/config.service';



@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  // animations: [ routerTransition ],
  styleUrls: ['./app.component.css'],
  providers: [ConfigService]
})


export class AppComponent {
  title = 'portfolio-website';

  constructor() {}

  // getState(outlet) {
  //   return outlet.activatedRouteData.state;
  // }



}


