import { Injectable } from '@angular/core';


@Injectable()
export class ConfigService {
    public config = new Config();
}

export class Config {
  projects = true;
  publications = true;

  activeSection = 0;
}
