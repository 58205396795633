import { Component, OnInit } from '@angular/core';

@Component ({
  selector: 'app-links',
  template: `
    <div class="contact" id="contact-icons">
      <ul>
        <!-- <li> <a href="https://www.linkedin.com/in/anke-van-oosterhout-1783359b/" target="_blank"> -->
        <!-- <fa-icon [icon]="['fab', 'linkedin-in']"></fa-icon></a></li> -->
        <!-- <li><a href="mailto:a.v.oosterhout@cs.au.dk" target="_blank"><fa-icon [icon]="['fas', 'at']"></fa-icon></a></li> -->
        <!-- <li><a href="https://twitter.com/AnkeOosterhout" target="_blank"><fa-icon [icon]="['fab', 'twitter']"></fa-icon></a> -->
        <!-- </li> -->
      </ul>
    </div>
  `,
  styles: [``]
})
export class LinksComponent implements OnInit {

  constructor() { }

  ngOnInit(): void { }
}
