import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})


export class PageService {

  activePage = 0;

  getActivePage() {
    return this.activePage;
  }

  setActivePage(value: number) {
    this.activePage = value;
  }
}
