import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { HttpClientModule } from '@angular/common/http';

import { AppComponent } from './app.component';
import { AppRoutingModule } from './app-routing.module';
import { WorkComponent } from './components/work/work.component';
import { SinglePageComponent } from './components/single-page/single-page.component';
import { SingleComponent } from './components/single-page/single.component';
import { LinksComponent }  from './components/shared/links.component';
import { AboutComponent } from './components/about/about.component';
import { AboutPageComponent } from './components/about/about-page.component';
import { ResearchComponent } from './components/research/research.component';
import { ResearchPageComponent } from './components/research/research-page.component';

import { ConfigService } from './services/config.service';
import { DrawingService } from './services/drawing.service';
import { ContentService } from './services/content.service';
import { InteractionService } from './services/interaction.service';
import { MorphService } from './services/morph.service';
import { CursorService } from './services/cursor.service';
import { DrawingPageService } from './services/drawing-page.service';
import { PageService } from './services/page.service';
import { InlineSVGModule } from 'ng-inline-svg';


@NgModule({
  declarations: [
    AppComponent,
    WorkComponent,
    SinglePageComponent,
    SingleComponent,
    AboutComponent,
    AboutPageComponent,
    ResearchComponent,
    ResearchPageComponent,
    LinksComponent
  ],
  imports: [
    AppRoutingModule,
    BrowserModule,
    BrowserAnimationsModule,
    HttpClientModule,
    InlineSVGModule.forRoot()
  ],
  providers: [
    ConfigService,
    ContentService,
    DrawingService,
    DrawingPageService,
    InteractionService,
    MorphService,
    CursorService,
    PageService
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
