import { DOCUMENT } from '@angular/common';
import { Inject, Injectable } from '@angular/core';
import * as THREE from 'three';

@Injectable()
export class MorphService {

  scene: any;
  camera: any;
  renderer: any;

  geometry: any;
  material: any;
  mesh: any;
  bgColor = new THREE.Color( 0xffffff );
  controls: any;


  constructor(@Inject(DOCUMENT) private document: Document) {}


  draw3D() {
    this.scene = new THREE.Scene();
    this.scene.background = this.bgColor;
    this.camera = new THREE.PerspectiveCamera( 75, window.innerWidth / window.innerHeight, 0.1, 1000);
    this.renderer = new THREE.WebGLRenderer( { canvas: (this.document.getElementById('bg') as HTMLCanvasElement) } );
    this.renderer.setPixelRatio( window.devicePixelRatio );
    this.renderer.setSize( window.innerWidth, window.innerHeight);

    // this.renderer.setClearColorHex( 0xffffff, 1 );
    this.camera.position.setZ(30);

    this.renderer.render( this.scene, this.camera );

    this.geometry = new THREE.TorusGeometry(10, 3, 16, 100);
    this.material = new THREE.MeshStandardMaterial( { color: 0x0e51fc });
    this.mesh = new THREE.Mesh( this.geometry, this.material );

    this.scene.add( this.mesh );

    const pointLight = new THREE.PointLight(0xcccccc);
    pointLight.position.set(25,25,25);
    const ambientLight = new THREE.AmbientLight(0xcccccc);

    this.scene.add(pointLight, ambientLight);

    this.renderer.render( this.scene, this.camera );

  }


  animate() {
    // requestAnimationFrame(this.animate);

    this.mesh.rotation.x += 0.01;
    this.mesh.rotation.y += 0.005;

    this.renderer.render( this.scene, this.camera );
  }

}
