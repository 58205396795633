<div class="wrapper" id="wrapper">

  <div class="nav">
    <div class="navigation left" id="left-button" (mouseenter)="this.cursorService.showCursorHighlight()" (mouseleave)="this.cursorService.hideCursorHighlight()" (click)="link('/')"><span></span>my work</div>
    <div class="navigation right active" id="right-button" (mouseenter)="this.cursorService.showCursorHighlight()" (mouseleave)="this.cursorService.hideCursorHighlight()" (click)="link('about')"><span></span>about me</div>
    <div class="mobile-nav"></div>
  </div>

  <div class="page-section start">
    <div id="aboutSVG"></div>
    <div class="designer-text-transition">
      <div class="first-word">
        <div class="word" id="graphic">
          <span class="letter">G</span>
          <span class="letter">R</span>
          <span class="letter">A</span>
          <span class="letter">P</span>
          <span class="letter">H</span>
          <span class="letter">I</span>
          <span class="letter">C</span>
        </div>
        <div class="word" id="interaction">
          <span class="letter">I</span>
          <span class="letter">N</span>
          <span class="letter">T</span>
          <span class="letter">E</span>
          <span class="letter">R</span>
          <span class="letter">A</span>
          <span class="letter">C</span>
          <span class="letter">T</span>
          <span class="letter">I</span>
          <span class="letter">O</span>
          <span class="letter">N</span>
        </div>
        <div class="word" id="industrial">
          <span class="letter">I</span>
          <span class="letter">N</span>
          <span class="letter">D</span>
          <span class="letter">U</span>
          <span class="letter">S</span>
          <span class="letter">T</span>
          <span class="letter">R</span>
          <span class="letter">I</span>
          <span class="letter">A</span>
          <span class="letter">L</span>
        </div>
      </div>
      <div class="second-word">
        <div class="word" id="designer">
          <span class="letter">D</span>
          <span class="letter">E</span>
          <span class="letter">S</span>
          <span class="letter">I</span>
          <span class="letter">G</span>
          <span class="letter">N</span>
          <span class="letter">E</span>
          <span class="letter">R</span>
        </div>
      </div>
      <div class="second-word">
        <div class="word" id="researcher">
          <span class="letter">&</span>
          <span class="letter">R</span>
          <span class="letter">E</span>
          <span class="letter">S</span>
          <span class="letter">E</span>
          <span class="letter">A</span>
          <span class="letter">R</span>
          <span class="letter">C</span>
          <span class="letter">H</span>
          <span class="letter">E</span>
          <span class="letter">R</span>
        </div>
      </div>
    </div>
    <div class="text-block-intro about">
      <div class="single-description">{{ this.text?.firstline }}</div>
    </div>
  </div>

  <div class="page-section text">
    <div class="page-section-inner-flex column">
      <div class="text-box">{{ this.text?.intro_paragraph }}</div>
    </div>
  </div>

  <div class="page-section noisy footer" id="page-footer">
    <!-- <div id="horizontal-container "> -->
    <div id="horizontal-content">
      <div class="results" *ngIf="this.searchActive && this.searchInput && this.publications.length > 0">Results: {{ this.publications.length }}</div>
      <div class="results" *ngIf="this.searchActive && this.searchInput && this.publications.length === 0">No items found</div>
      <ul class="publication-list" id="publication-list">
        <li class="publication-list-item" *ngFor="let publication of this.publications">
          <div class="publication-item-content" (click)="openUrl(publication.url)">
            <div class="publication-item">
              <p class="publication-title">{{ publication?.title }}</p>
              <p class="publication-authors">{{ publication?.authors }}</p>
              <!-- <div class="publication-download-buttons">
                <div class="publication-link download-pdf"><img src="/assets/images/export.svg"></div>
                <div class="publication-link acm-url"><img src="/assets/images/export.svg"></div>
              </div> -->
              <p class="publication-conference">{{ publication?.conference }} {{ publication?.type }}</p>
            </div>
          </div>
        </li>
      </ul>
    </div>
    <!-- </div> -->
    <!-- <div class="horizontalScrollbar">
      <input type="range" min="0" max="100" value="1" class="slider" id="scrollOffsetPublications" (input)="updateScrollOffsetPublications()">
    </div> -->

    <div class="large-title small" id="section-title-publications">Publications</div>
    <div class="search-window" [ngClass]="{ open: this.searchActive }">
      <div class="search-input" *ngIf="this.searchActive">
        <input type="text" name="search" id="search-input" [(ngModel)]="this.searchInput" placeholder="search" autofocus (change)="searchPublications()" spellcheck="false">
      </div>
    </div>
    <div class="search-icon" (click)="this.showSearch()"><img src="/assets/images/search-icon.svg"></div>
  </div>

  <!-- <div class="page-section footer"> -->
    <!-- <div class="section-title">Education</div> -->
    <!-- <div class="section-content"> -->
      <!-- <div class="line"></div>
      <ul class="publication-list" *ngFor="let data of this.degrees">
        <div class="year-box">
          <div class="publication-year">{{ data?.year }}</div><div class="year-dot"></div>
        </div>
        <li class="publication-list-item" *ngFor="let degree of data?.degree">
          <div class="publication-item" (mouseenter)="showImagePreview(degree?.image)" (mouseleave)="hideImagePreview()">
            <p class="publication-title">{{ degree?.study }}</p>
            <p class="publication-conference">{{ degree?.institution }}</p>
            <p class="publication-authors">{{ degree?.period }}</p>
          </div>
        </li>
      </ul> -->
    <!-- </div> -->
  <!-- </div> -->



  <div class="footerBG" id="footerBG">
    <div id="footerSVG"></div>
    <div class="footer-text">
      Have a look at <br>some of <span class="in-text-link" (click)="this.link('/')">my work</span>.

    </div>
    <div class="social-media-icons">
      <a href="https://www.linkedin.com/in/anke-van-oosterhout-1783359b/" target="_blank">
        <div class="icon linkedin"><img src="/assets/images/linkedin.svg"></div></a>
      <a href="/">
        <div class="icon twitter"><img src="/assets/images/twitter.svg"></div></a>
    </div>
  </div>


</div>

<div id="cursor"></div>
