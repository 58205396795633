import { DOCUMENT } from '@angular/common';
import { Inject, Injectable } from '@angular/core';
import * as d3 from 'd3';
import { Subject } from 'rxjs';

@Injectable()
export class DrawingService {

  public height = window.innerHeight;
  public width = window.innerWidth;
  // svg: any;
  mainGroup: any;
  data: any;
  line: any;
  y: any;
  x: any;
  public swingCord = [];
  public projects = [];
  pathSmall: any;
  pathSmall2: any;
  totalLength = [];
  distance: number;
  public activeSection = 0;
  animating = false;



  animateScroll: Subject<any> = new Subject();
  getProjectList: Subject<any> = new Subject();
  route: Subject<any> = new Subject();

  navigationList = [
    { page: 'about', title: 'more about me', width: 0 },
    { page: 'research', title: 'my research', width: 0 }
  ];

  constructor(@Inject(DOCUMENT) private document: Document) {}

  public animateScrollPage(offset: number) {
    this.animateScroll.next(offset);
  }

  public getProjects() {
    this.getProjectList.next();
  }

  public routeLink(link: string) {
    this.route.next(link);
  }

  resetVariables() {
    this.height = window.innerHeight;
    if (window.innerWidth < this.height * 0.8) {
      this.width = window.innerWidth * 1.7;
      this.height = this.width / 16 * 9;

    } else {
      this.width = window.innerWidth;
      this.height = window.innerHeight;
    }
    this.line = null;
    this.data = [];
    this.pathSmall = null;
    this.pathSmall2 = null;
    this.totalLength = [];
    this.x = d3.scaleLinear().domain([0, 100]).range([0, this.width]);
    this.y = d3.scaleLinear().domain([0, 100]).range([0, this.height]);
  }

  draw(first = true) {
    if (first) { this.animating = true; }
    if (this.projects.length === 0) {
      this.getProjects();
    }

    const strokeWidth = this.width * 0.045 > 36 ? this.width * 0.045 : 36;

    this.resetVariables();

    d3.selectAll('#illustration').remove();

    const svg = d3.select('#svg')
      .append('svg')
      .attr('width', this.width)
      .attr('height', window.innerHeight * 2.2)
      .attr('id', 'illustration');

    this.mainGroup = svg.append('g')
      .attr('transform', () => {
        if (window.innerWidth >= window.innerHeight) {
          return 'translate(0, ' + (window.innerHeight * 0.2) + ')';
        } else {
          return 'translate(0, ' + (((window.innerHeight - this.width) / 2) + window.innerHeight * 0.4) + ')';
        }
      });

    // const radialGradient = svg.append('defs')
    //   .append('radialGradient')
    //   .attr('id', 'radial-gradient');

    // radialGradient.append('stop')
    //     .attr('offset', '20%')
    //     .attr('stop-color', '#fcf8f1');

    // radialGradient.append('stop')
    //   .attr('offset', '80%')
    //   .attr('stop-color', '#fddf31');

    this.mainGroup.append('rect')
      .attr('x', 0)
      .attr('y', 0)
      .attr('width', this.width)
      .attr('height', window.innerHeight * 2.4)
      .attr('fill', '#d4dcf0') //E7D3F5
      .attr('transform', 'translate(0, ' + (window.innerHeight * -0.4) + ')');

    // this.mainGroup.append('circle')
    //   .attr('id', 'mouseGradient')
    //   .attr('cx', this.width / 2)
    //   .attr('cy', window.innerHeight / 2)
    //   .attr('r', this.width / 3)
    //   .attr('fill', 'url(#radial-gradient)')
    //   .attr('pointer-events', 'none')
    //   .attr('opacity', 0.5);

    this.data = [
      [
        [-4, 53 ],
        [ 1,  75 ],
        [ 16,  75 ],
        [ 33,  -2 ],
        [ 47,  -2 ],
        [ 50,  40 ],
        [ 50,  70 ],
        [ 50,  88 ]
      ],
      [
        [ 36, 68 ],
        [ 25, 44.5 ],
        [ 20, 36 ]
      ],
      [
        [ 20,  36 ],
        [ 20,  37 ],
        [ 30,  54 ],
        [ 55,  74 ],
        [ 100,  78 ]
      ],
      [
        [ 50, 88 ],
        [ 50, 100 ],
        [ 50, 200 ]
      ],
      [
        [ 50, 88 ],
        [ 50, 88 ]
      ]
    ];

    this.line = d3.line()
      .x((d) => this.x(d[0]))
      .y((d) => this.y(d[1]))
      .curve(d3.curveNatural);

    this.pathSmall = this.mainGroup.append('path')
      .attr('d', this.line(this.data[1]))
      .attr('stroke', 'transparent')
      .attr('stroke-width', strokeWidth/3)
      .attr('fill', 'none');

    const path = this.mainGroup.append('path')
      .attr('d', this.line(this.data[0]))
      .attr('stroke', '#fff')
      .attr('stroke-width', strokeWidth)
      .attr('fill', 'none');

    const mainLine = this.mainGroup.append('path')
      .attr('d', this.line(this.data[3]))
      .attr('stroke', 'transparent')
      .attr('stroke-width', strokeWidth)
      .style('stroke-linecap', 'butt')
      .attr('fill', 'none');

    const scrollLine = this.mainGroup.append('path')
      .attr('d', this.line(this.data[4]))
      .attr('id', 'scrollLine')
      .attr('stroke', '#fff')
      .attr('stroke-width', strokeWidth)
      .attr('fill', 'none');

    this.pathSmall2 = this.mainGroup.append('path')
      .attr('d', this.line(this.data[2]))
      .attr('stroke', 'transparent')
      .attr('stroke-width', strokeWidth/3)
      .attr('fill', 'none');

    const textSVG = this.mainGroup.append('g')
      .attr('transform', 'translate(' + [this.width * 0.568, this.height * 0.37] + ')');

    this.totalLength = [ path.node().getTotalLength(), this.pathSmall.node().getTotalLength(), this.pathSmall2.node().getTotalLength(), mainLine.node().getTotalLength()];

    if (first) {
      path
        .attr('stroke-dasharray', this.totalLength[0] + ' ' + this.totalLength[0] )
        .attr('stroke-dashoffset', this.totalLength[0] )
        .transition()
        .duration(1500)
        .ease(d3.easeLinear)
        .attr('stroke-dashoffset', 0);
    }


    setTimeout(() => {
      mainLine
        .style('stroke', 'rgba(255,255,255,0.2)');

      if (first) {
        mainLine
          .attr('stroke-dasharray', this.totalLength[3] + ' ' + this.totalLength[3] )
          .attr('stroke-dashoffset', this.totalLength[3] )
          .transition()
          .duration(2500)
          .ease(d3.easeLinear)
          .attr('stroke-dashoffset', 0);
      }
    }, (first ? 1500 : 0));


    setTimeout(() => {
      const scrollLineEnd = this.mainGroup.append('line')
        .attr('id', 'scrollLineEnd')
        .attr('x1', this.width * 0.5 - (this.width * 0.027))
        .attr('x2', this.width * 0.5 + (this.width * 0.027))
        .attr('y1', (this.height * 0.88))
        .attr('y2', (this.height * 0.88))
        .attr('stroke', '#000')
        .attr('stroke-width', this.width * 0.005);

      this.setSwingCordVariables(this.y);

      const cord = this.mainGroup.append('path')
        .attr('d', () => first ? this.line(this.swingCord[0]) : this.line(this.swingCord[1]))
        .attr('id', 'cord')
        .style('stroke', '#000')
        .style('stroke-width', 2)
        .style('fill', 'none');


      if (first) {
        d3.select('#cord')
          .attr('d', this.line(this.swingCord[0]))
          .transition()
          .ease(d3.easeLinear)
          .attr('d', this.line(this.swingCord[1]))
          .transition()
          .ease(d3.easeLinear)
          .attr('d', this.line(this.swingCord[2]))
          .transition()
          .ease(d3.easeLinear)
          .attr('d', this.line(this.swingCord[1]))
          .transition()
          .ease(d3.easeLinear)
          .attr('d', this.line(this.swingCord[3]))
          .transition()
          .ease(d3.easeLinear)
          .attr('d', this.line(this.swingCord[1]))
          .transition()
          .ease(d3.easeLinear)
          .attr('d', this.line(this.swingCord[4]))
          .transition()
          .ease(d3.easeLinear)
          .attr('d', this.line(this.swingCord[1]));
      }


      const dragRing = d3.drag()
        .subject((d) => { return d; })
        .on('start', () => {
          if (!this.document.getElementById('cursor').classList.contains('is-moving')) {
            this.document.getElementById('cursor').classList.add('is-moving');
          }
        })
        .on('drag', (d) =>  {
          if (!this.animating) {
            const yStartPos = (this.height * 0.882) + 30 + (this.width * 0.01);
            const diff = d.y - yStartPos;

            if (diff < 15) {
              this.swingCord[1] = [
                [50, 88.2],
                [50, 88.2],
                [50, (this.y.invert(this.height * 0.882 + 30 + diff))]
              ];
            } else {
              this.swingCord[1] = [
                [50, (this.y.invert(this.height * 0.882 + (diff - 15)))],
                [50, (this.y.invert(this.height * 0.882 + (diff - 15)))],
                [50, (this.y.invert(this.height * 0.882 + 30 + diff))]
              ];

              this.data[4] = [
                [ 50, 87 ],
                [ 50, (this.y.invert(this.height * 0.882 + (diff - 15))) ]
              ];
              d3.select('#scrollLine').attr('d', this.line(this.data[4]));
              d3.select('#scrollLineEnd').attr('y1', this.height * 0.88 + (diff - 15)).attr('y2', (this.height * 0.88 + (diff - 15)));
            }
            d3.select('#cord').attr('d', this.line(this.swingCord[1]));
            d3.select('#ring').attr('cy', (this.height * 0.882) + 30 + (this.width * 0.01) + diff);
          }
          if (d.y < window.innerHeight + 200) {
            this.document.getElementById('cursor').style.top = d.y + 'px';
          } else {
            this.document.getElementById('cursor').style.top = (d.y%window.innerHeight) + 'px';
          }
          this.document.getElementById('cursor').style.left = d.x + 'px';
          if (!this.document.getElementById('cursor').classList.contains('is-moving')) {
            this.document.getElementById('cursor').classList.add('is-moving');
          }
        })
        .on('end', (d) => {

          if (this.document.getElementById('cursor').classList.contains('is-moving')) {
            this.document.getElementById('cursor').classList.remove('is-moving');
          }

          if (d.y - ((this.height * 0.88) + 30 + (this.width * 0.01)) > 20) {
            d3.selectAll('.pull-down').remove();

            setTimeout(() => {
              if (d.y > window.innerHeight + (this.distance * (this.projects.length + 0.8))) {
                this.updateScrollLine(0);
                this.animateScrollPage(0);
              } else if (d.y < window.innerHeight + this.distance)  {
                this.updateScrollLine(0);
                this.animateScrollPage(window.innerHeight);
                this.updateScrollLine(1);
                setTimeout(() => this.animateCord(1, this.y, (d.y - window.innerHeight) / this.distance), 300);
              } else {
                for (let n = 1; n <= this.projects.length; n++) {
                  if (d.y <= window.innerHeight + (this.distance * (n + 0.8)) && d.y >= window.innerHeight + (this.distance * (n - 0.2))) {
                    this.updateScrollLine(n);
                    setTimeout(() => this.animateCord(n, this.y, (d.y - window.innerHeight) / this.distance), 300);
                  }
                }
              }

            }, 100);
          } else {
            this.updateScrollLine(0);
          }

        });

      const ring = this.mainGroup.append('circle')
        .attr('id', 'ring')
        .attr('r', this.width * 0.01)
        .attr('cx', this.width * 0.5)
        .attr('cy', (this.height * 0.882) + 30 + (this.width * 0.01))
        .attr('stroke', '#000')
        .attr('stroke-width', this.width * 0.0045)
        .attr('fill', 'transparent')
        .attr('cursor', 'grab')
        .attr('shape-rendering', 'geometricPrecision')
        .call(dragRing);

      if (first) {

        d3.select('#ring')
          .attr('cy', this.height * 0.88 + 23 + (this.width * 0.01))
          .attr('cx', this.width * 0.52)
          .transition()
          .ease(d3.easeLinear)
          .attr('cx', this.width * 0.5)
          .attr('cy', (this.height * 0.88) + 30 + (this.width * 0.01))
          .transition()
          .ease(d3.easeLinear)
          .attr('cy', this.height * 0.88 + 23 + (this.width * 0.01))
          .attr('cx', this.width * 0.485)
          .transition()
          .ease(d3.easeLinear)
          .attr('cx', this.width * 0.5)
          .attr('cy', (this.height * 0.88) + 30 + (this.width * 0.01))
          .transition()
          .ease(d3.easeLinear)
          .attr('cy', this.height * 0.88 + 25 + (this.width * 0.01))
          .attr('cx', this.width * 0.51)
          .transition()
          .ease(d3.easeLinear)
          .attr('cx', this.width * 0.5)
          .attr('cy', (this.height * 0.88) + 30 + (this.width * 0.01))
          .transition()
          .ease(d3.easeLinear)
          .attr('cy', this.height * 0.88 + 28 + (this.width * 0.01))
          .attr('cx', this.width * 0.495)
          .transition()
          .ease(d3.easeLinear)
          .attr('cx', this.width * 0.5)
          .attr('cy', (this.height * 0.88) + 30 + (this.width * 0.01));
      }

      this.pathSmall.attr('stroke', '#000');

      if (first) {
        this.pathSmall
          .attr('stroke-dasharray', this.totalLength[1]  + ' ' + this.totalLength[1] )
          .attr('stroke-dashoffset', this.totalLength[1] )
          .transition()
          .duration(400)
          .ease(d3.easeLinear)
          .attr('stroke-dashoffset', 0);
      }
    }, (first ? 1600 : 0));


    setTimeout(() => {
      this.pathSmall2.attr('stroke', '#444');

      if (first) {
        this.pathSmall2
          .attr('stroke-dasharray', this.totalLength[2]  + ' ' + this.totalLength[2] )
          .attr('stroke-dashoffset', this.totalLength[2] )
          .transition()
          .duration(800)
          .ease(d3.easeLinear)
          .attr('stroke-dashoffset', 0);
      }

    }, (first ? 2000 : 0));

    // setTimeout(() => {
    //   textSVG.append('text')
    //     .attr('x', 0)
    //     .attr('y', 0)
    //     .text('Hi!')
    //     .attr('class', 'intro-text first-line');
    // }, (first ? 2800: 0))


    // setTimeout(() => {
    //   textSVG.append('text')
    //     .attr('x', 0)
    //     .attr('y', '3vw')
    //     .text('My name is Anke.')
    //     .attr('class', 'intro-text');

    //   textSVG.append('text')
    //     .attr('x', 0)
    //     .attr('y', '5.2vw')
    //     .text('I am designer and researcher')
    //     .attr('class', 'intro-text');

    //   textSVG.append('text')
    //     .attr('x', 0)
    //     .attr('y', '7.4vw')
    //     .text('passionate about designing')
    //     .attr('class', 'intro-text');

    //   textSVG.append('text')
    //     .attr('x', 0)
    //     .attr('y', '9.6vw')
    //     .html('aesthetic interactions with')
    //     .attr('class', 'intro-text');

    //   textSVG.append('text')
    //     .attr('x', 0)
    //     .attr('y', '11.8vw')
    //     .html('digital and physical products.')
    //     .attr('class', 'intro-text');

    //   textSVG.on('mouseenter', () => {
    //     if (!this.document.getElementById('cursor').classList.contains('highlight')) {
    //       this.document.getElementById('cursor').classList.add('highlight');
    //     }
    //   })
    //   .on('mouseleave', () => {
    //     if (this.document.getElementById('cursor').classList.contains('highlight')) {
    //       this.document.getElementById('cursor').classList.remove('highlight');
    //     }
    //   });

    // }, (first ? 2950: 0));


    setTimeout(() => {
      if (this.activeSection === 0 && first) {
        const text = this.mainGroup.append('text')
          .attr('class', 'pull-down')
          .attr('x', this.width * 0.568)
          .attr('y', this.height * 0.882 + 53)
          .text('Pull down');

        const arrow = this.mainGroup.append('image')
          .attr('class', 'pull-down')
          .attr('xlink:href', '/assets/images/arrow.svg')
          .attr('x', this.width * 0.568 - 35)
          .attr('y', this.height * 0.882 + 37)
          .attr('width', 36)
          .attr('height', 18);

        for (let n = 0; n < 2; n++) {
          arrow
            .attr('x', this.width * 0.568 - 42)
            .transition()
            .ease(d3.easeLinear)
            .duration(350)
            .attr('x', this.width * 0.568 - 35)
            .transition()
            .ease(d3.easeLinear)
            .duration(350)
            .attr('x', this.width * 0.568 - 42)
        }
      }
      if (first) { this.animating = false; }

    }, (first ? 3000 : 0));

  }


  // drawLinks() {

  //   const navigation = this.mainGroup.append('g')
  //     .attr('id', 'navigation');

  //   const about = navigation.selectAll('text.navigation')
  //     .data(this.navigationList)
  //     .enter()
  //     .append('text')
  //     .attr('x', (d) => d.page === 'about' ? -this.height / 2 : this.height / 2)
  //     .attr('y', (d) => d.page === 'about' ? window.innerWidth * 0.97 : -window.innerWidth * 0.03)
  //     .attr('id', (d) => 'nav' + d.page)
  //     .attr('class', 'navigation')
  //     .attr('transform', (d) => d.page === 'about' ? 'rotate(-90)':'rotate(90)')
  //     .text((d) => d.title)
  //     .attr('text-anchor', 'middle')
  //     .style('fill', '#000')
  //     .on('mouseenter', (d) => {
  //       if (!this.document.getElementById('cursor').classList.contains('highlight')) {
  //         this.document.getElementById('cursor').classList.add('highlight');
  //       }
  //       d3.select('#n-'+ d.srcElement.id.substring(3))
  //         .attr('y2', this.height / 2 - (this.document.getElementById(d.srcElement.id).getBoundingClientRect().height / 2))
  //         .transition()
  //         .ease(d3.easeLinear)
  //         .duration(400)
  //         .attr('y2', this.height / 2 + (this.document.getElementById(d.srcElement.id).getBoundingClientRect().height / 2))
  //         .transition()
  //         .ease(d3.easeLinear)
  //         .duration(400)
  //         .attr('y1', this.height / 2 + (this.document.getElementById(d.srcElement.id).getBoundingClientRect().height / 2))
  //         .transition()
  //         .ease(d3.easeLinear)
  //         .duration(400)
  //         .attr('y1', this.height / 2 - (this.document.getElementById(d.srcElement.id).getBoundingClientRect().height / 2))
  //     })
  //     .on('mouseleave', (d) => {
  //       if (this.document.getElementById('cursor').classList.contains('highlight')) {
  //         this.document.getElementById('cursor').classList.remove('highlight');
  //       }
  //       d3.select('#n-'+ d.srcElement.id.substring(3))
  //         .attr('y2', this.height / 2 + (this.document.getElementById(d.srcElement.id).getBoundingClientRect().height / 2))
  //         .transition()
  //         .ease(d3.easeLinear)
  //         .duration(400)
  //         .attr('y2', this.height / 2 - (this.document.getElementById(d.srcElement.id).getBoundingClientRect().height / 2))

  //       setTimeout(() => {
  //         d3.select('#n-'+ d.srcElement.id.substring(3))
  //           .attr('y2', this.height / 2 - (this.document.getElementById(d.srcElement.id).getBoundingClientRect().height / 2))
  //           .attr('y1', this.height / 2 - (this.document.getElementById(d.srcElement.id).getBoundingClientRect().height / 2))
  //       }, 400);
  //     })
  //     .on('click', (d) => {
  //       // localStorage.setItem('activeItem', '0');
  //       this.routeLink(d.srcElement.id.substring(3))
  //     });


  //   const line = navigation.selectAll('line')
  //     .data(this.navigationList)
  //     .enter()
  //     .append('line')
  //     .attr('x1', (d) => d.page === 'about' ? (window.innerWidth * 0.97) + 8 : (window.innerWidth * 0.03) - 8)
  //     .attr('x2', (d) => d.page === 'about' ? (window.innerWidth * 0.97) + 8 : (window.innerWidth * 0.03) - 8)
  //     .attr('y1', (d) => this.height / 2 - (this.document.getElementById('nav' + d.page).getBoundingClientRect().height / 2))
  //     .attr('y2', (d) => this.height / 2 - (this.document.getElementById('nav' + d.page).getBoundingClientRect().height / 2))
  //     .attr('class', 'navigationLine')
  //     .attr('id', (d) => 'n-' + d.page)
  //     .attr('stroke','#000')
  //     .attr('shape-rendering', 'crispEdges')
  //     .attr('stroke-width', 1);
  // }



  drawTimeLineLabels() {

    d3.selectAll('#timelineLabels').remove();

    const timelineLabels = this.mainGroup.append('g')
      .attr('id', 'timelineLabels')
      .attr('transform', 'translate(' + [ 0, this.height ] + ')');

    this.distance = this.projects.length > 0 ? window.innerHeight / (this.projects.length + 1) : window.innerHeight / 5;

    timelineLabels.selectAll('line.tick')
      .data(this.projects)
      .enter()
      .append('line')
      .attr('id', (d, i) => 'tick-' + d.title)
      .attr('class', 'tick')
      .attr('x1', this.width * 0.5 - (this.width * 0.045))
      .attr('x2', this.width * 0.5 - (this.width * 0.035))
      .attr('y1', (d,i) => (this.distance * (i + 1)))
      .attr('y2', (d,i) => (this.distance * (i + 1)))
      .style('stroke', '#000')
      .style('stroke-width', 8)
      .style('opacity', 0
      )
      .attr('shape-rendering', 'crispEdges')
      .on('click', (d, i) => {
        this.updateScrollLine(i.id);
       })
      .on('mouseover', (d) =>
          d3.select('#tick-' + d.title)
            .style('opacity', 1));

    timelineLabels.selectAll('line.tick1')
      .data(this.projects)
      .enter()
      .append('line')
      .attr('class', 'tick1')
      .attr('x1', this.width * 0.5 - (this.width * 0.045))
      .attr('x2', this.width * 0.5 - (this.width * 0.035))
      .attr('y1', (d,i) => (this.distance * (i + 1)))
      .attr('y2', (d,i) => (this.distance * (i + 1)))
      .style('stroke', 'rgba(0,0,0,0.8)')
      .style('stroke-width', 1)
      .style('stroke-linecap', 'butt')
      .attr('shape-rendering', 'crispEdges')
      .attr('pointer-events', 'none');
  }

  animateCord(index: number, y: any, offset: number) {
    this.animating = true;
    const offsetVal = offset - Math.floor(offset);
    if (index > 0) {
      this.swingCord[1] = [
        [50, this.y.invert(this.height + (this.distance * index))],
        [50, this.y.invert(this.height + (this.distance * index) + 10)],
        [50, this.y.invert(this.height + (this.distance * index) + 20)],
        [50, this.y.invert(this.height + (this.distance * index) + 30)]
      ];

      this.swingCord[5] = [
        [50, this.y.invert(this.height + (this.distance * index))],
        [50 - (offsetVal/2), this.y.invert(this.height + (this.distance * index) + 9)],
        [50 + (offsetVal/1.5), this.y.invert(this.height + (this.distance * index) + 11)],
        [50, this.y.invert(this.height + (this.distance * index) + 20)]
      ];

      d3.select('#cord')
        .attr('d', this.line(this.swingCord[1]))
        .transition()
        .duration(300)
        .ease(d3.easeCircleIn)
        .attr('d', this.line(this.swingCord[5]))
        .transition()
        .duration(300)
        .ease(d3.easeCircleIn)
        .attr('d', this.line(this.swingCord[1]));

      d3.select('#ring')
        .attr('cy', this.height + (this.distance * index) + 30 + (this.width * 0.01))
        .transition()
        .duration(300)
        .ease(d3.easeCircleIn)
        .attr('cy', this.height + (this.distance * index) + 20 + (this.width * 0.01))
        .transition()
        .duration(300)
        .ease(d3.easeCircleIn)
        .attr('cy', this.height + (this.distance * index) + 30 + (this.width * 0.01));

      setTimeout(() => {
        this.animating = false;
      }, 600);
    }
  }


  setSwingCordVariables(y: any) {
    this.swingCord = [
      [
        [50, 88.2],
        [50.5, (y.invert(this.height * 0.882 + 12))],
        [51.2, (y.invert(this.height * 0.882 + 23))]
      ],
      [
        [50, 88.2],
        [50, 88.2],
        [50, (y.invert(this.height * 0.882 + 30))]
      ],
      [
        [50, 88.2],
        [49.5, (y.invert(this.height * 0.882 + 12))],
        [48.8, (y.invert(this.height * 0.882 + 23))]
      ],
      [
        [50, 88.2],
        [50.2, (y.invert(this.height * 0.882 + 10))],
        [50.8, (y.invert(this.height * 0.882 + 25))]
      ],
      [
        [50, 88.2],
        [49.8, (y.invert(this.height * 0.882 + 10))],
        [49.5, (y.invert(this.height * 0.882 + 28))]
      ],
      [
        [50, 88.2],
        [46, (y.invert(this.height * 0.882 + 15))],
        [50, (y.invert(this.height * 0.882 + 30))]
      ]
    ];
  }

  updateScrollLine(index: number, animate = true) {
    this.activeSection = index;

    if (this.swingCord.length === 0) {
      this.setSwingCordVariables(this.y);
    }

    if (index === 0) {
      this.swingCord[1] = [
        [50, 88.2],
        [50, 88.2],
        [50, (this.y.invert(this.height * 0.882 + 30))]
      ];
      this.data[4] = [
        [ 50, 87 ],
        [ 50, 88 ]
      ];
      if (animate) {
        d3.select('#ring').transition().ease(d3.easeCircleIn).attr('cy', (this.height * 0.882) + 30 + (this.width * 0.01));
        d3.select('#cord').transition().ease(d3.easeCircleIn).attr('d', this.line(this.swingCord[1]));
        d3.select('#scrollLine').transition().ease(d3.easeCircleIn).attr('d', this.line(this.data[4]));
        d3.select('#scrollLineEnd').transition().ease(d3.easeCircleIn).attr('y1', this.height * 0.88).attr('y2', (this.height * 0.88));
      }
    } else {
      this.swingCord[1] = [
        [50, this.y.invert(this.height + (this.distance * index))],
        [50, this.y.invert(this.height + (this.distance * index))],
        [50, this.y.invert(this.height + (this.distance * index) + 30)]
      ];
      this.data[4] = [
        [ 50, 80 ],
        [ 50, this.y.invert(this.height + (this.distance * index)) ]
      ];
      if (animate) {
        d3.select('#ring').transition().ease(d3.easeCircleIn).attr('cy', this.height + (this.distance * index) + 30 + (this.width * 0.01));
        d3.select('#cord').transition().ease(d3.easeCircleIn).attr('d', this.line(this.swingCord[1]));
        d3.select('#scrollLine').transition().ease(d3.easeCircleIn).attr('d', this.line(this.data[4]));
        d3.select('#scrollLineEnd').transition().ease(d3.easeCircleIn).attr('y1', this.height + (this.distance * index)).attr('y2', this.height + (this.distance * index));
      } else {
        d3.select('#ring').attr('cy', this.height + (this.distance * index) + 30 + (this.width * 0.01));
        d3.select('#cord').attr('d', this.line(this.swingCord[1]));
        d3.select('#scrollLine').attr('d', this.line(this.data[4]));
        d3.select('#scrollLineEnd').attr('y1', this.height + (this.distance * index)).attr('y2', this.height + (this.distance * index));
      }
    }

  }

  redraw() {
    this.draw(false);
    this.drawTimeLineLabels();
    setTimeout(() => {
      this.updateScrollLine(this.activeSection, false);
    }, 100);
  }

  updateRadialBackground(x: number, y: number) {
    d3.select('#mouseGradient').attr('cx', x).attr('cy', y);
  }

  hidePage(index: number) {

    // const ringX = d3.select('#ring').attr('cx');
    // const ringY = d3.select('#ring').attr('cy');
    this.document.getElementById('wrapper').classList.add('clip-' + index);
  }


  showPage(index: number) {
    this.document.getElementById('wrapper').style.transition = 'clip-path 0s';
    this.document.getElementById('wrapper').classList.add('clip-' + index);

    setTimeout(() => {
      this.document.getElementById('wrapper').style.transition = 'clip-path 0.5s'
      this.document.getElementById('wrapper').classList.remove('clip-' + index)
    }, 100);
  }

  removeClippath(index: number) {
    this.document.getElementById('wrapper').classList.remove('clip-' + index);
  }

}
