import { Component, OnInit, HostListener, Inject } from '@angular/core';
import { Router } from '@angular/router';
import { DOCUMENT } from '@angular/common';
import { ContentService } from 'src/app/services/content.service';
import { CursorService } from 'src/app/services/cursor.service';
import { DrawingPageService } from 'src/app/services/drawing-page.service';
import { MorphService } from 'src/app/services/morph.service';
import * as THREE from 'three';


@Component({
  selector: 'app-research',
  templateUrl: './research.component.html',
  styleUrls: ['./research.component.css']
})
export class ResearchComponent implements OnInit {

  constructor(@Inject(DOCUMENT) private document: Document, public cursorService: CursorService, private router: Router, private drawingPageService: DrawingPageService,
              private morphService: MorphService) {

                this.drawingPageService.navLink.subscribe(res => {
                  this.link(res);
                });

              }

  @HostListener('document:mousemove', ['$event'])
  onMouseMove(e: MouseEvent) {

    this.cursorService.moveCursor(e.pageX, e.pageY);

    this.morphService.animate();

    // if ( WEBGL.isWebGLAvailable() ) {

    //   // Initiate function or other initializations here


    // } else {

    //   // const warning = WEBGL.getWebGLErrorMessage();
    //   // document.getElementById( 'container' ).appendChild( warning );

    // }

  }

  ngOnInit(): void {
    this.morphService.draw3D();

    this.drawingPageService.drawFooter();
  }



  link(link: string) {
    this.router.navigate(['', link]);
  }

}
