import { Component, OnInit, HostListener, Inject, Input } from '@angular/core';
import { DOCUMENT } from '@angular/common';
import { ContentService } from 'src/app/services/content.service';
import { CursorService } from 'src/app/services/cursor.service';
import { DrawingPageService } from 'src/app/services/drawing-page.service';
import { Router } from '@angular/router';

@Component({
  selector: 'app-about',
  templateUrl: './about.component.html',
  styleUrls: ['./about.component.css']
})
export class AboutComponent implements OnInit {

  publications = [];
  experience: any;
  degrees = [];
  text: any;
  imageVisible = false;
  mouseXpercentage = 0;
  mouseYpercentage = 0;
  searchActive = false;
  searchInput: string = null;
  allPublications = [];

  constructor(@Inject(DOCUMENT) private document: Document, private contentService: ContentService, public cursorService: CursorService,
              private drawingPageService: DrawingPageService, private router: Router) {

      this.drawingPageService.navLink.subscribe(res => {
        this.link(res);
      });

  }

  @HostListener('document:mousemove', ['$event'])
  onMouseMove(e: MouseEvent) {

    this.mouseXpercentage = Math.round(e.pageX / window.innerWidth);
    this.mouseYpercentage = Math.round(e.pageY / window.innerHeight);

    this.cursorService.moveCursor(e.pageX, e.pageY);

    this.drawingPageService.updateAmplitude(this.mouseXpercentage / 2 + 0.5, this.mouseYpercentage / 2 + 0.5, window.scrollY);

  }

  @HostListener('window:scroll', ['$event'])
  onWindowScroll(e) {
    this.drawingPageService.updateAmplitude(this.mouseXpercentage / 2 + 0.5, this.mouseYpercentage / 2 + 0.5, window.scrollY);
  }

  @HostListener('window:resize', [])
  onWindowResize() {
    this.drawingPageService.drawAbout();
    this.drawingPageService.drawFooter();
  }



  ngOnInit(): void {
    this.contentService.getPublications().subscribe(res => {
      this.allPublications = res['publications'];
      this.publications = this.allPublications;
    });

    this.contentService.getSkills().subscribe(res => {
      this.experience = res['experience'];
    });

    this.contentService.getDegrees().subscribe(res => {
      this.degrees = res['degrees'];
    });

    this.contentService.getTextAbout().subscribe(res => {
      this.text = res['data'];
    });

    this.drawingPageService.drawAbout();
    this.drawingPageService.drawFooter();
    setTimeout(() => { this.drawingPageService.startCircleAnimation(); }, 2000);
  }


  showSearch() {
    this.searchActive = !this.searchActive;
    if (this.searchActive) {
      this.document.getElementById('section-title-publications').classList.add('disable-blend-mode');
    } else {
      this.document.getElementById('section-title-publications').classList.remove('disable-blend-mode');
      this.publications = this.allPublications;
    }
  }

  searchPublications() {
    this.searchInput = (this.document.getElementById('search-input') as HTMLInputElement).value;
    this.publications = [];
    for (const publication of this.allPublications) {
      if (publication.title.toLowerCase().includes(this.searchInput.toLowerCase()) ||
          publication.authors.toLowerCase().includes(this.searchInput.toLowerCase()) ||
          publication.keywords.toLowerCase().includes(this.searchInput.toLowerCase()) ||
          publication.date.toLowerCase().includes(this.searchInput.toLowerCase()) ||
          publication.conference.toLowerCase().includes(this.searchInput.toLowerCase()) ||
          publication.type.toLowerCase().includes(this.searchInput.toLowerCase()) ||
          publication.abstract.toLowerCase().includes(this.searchInput.toLowerCase())) {
        this.publications.push(publication);
      }
    }
    (this.document.getElementById('scrollOffsetPublications') as HTMLInputElement).value = '0';
    this.document.getElementById('publication-list').style.marginLeft = '0';
  }

  updateScrollOffsetPublications() {
    const input = parseInt((this.document.getElementById('scrollOffsetPublications') as HTMLInputElement).value);
    const inputScrollableWidth = this.document.getElementById('publication-list').getBoundingClientRect().width - window.innerWidth;
    const ratio = inputScrollableWidth / window.innerWidth;
    if (inputScrollableWidth > 0) {
      const newScrollOffset = ratio * input * -1;
      this.document.getElementById('publication-list').style.marginLeft = newScrollOffset + '%';
    }
    // console.log(input, inputWidth, (inputWidth * parseInt(input) / 100));
    // this.document.getElementById('horizontal-container').scrollTo((inputWidth * parseInt(input) / 100),0);
  }

  link(link: string) {
    window.scrollTo(0,0);
    this.router.navigate(['', link]);
  }

  openUrl(url: string) {
    if (url) {
      window.open(url, '_blank');
    }
  }
}
