import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { ContentService } from '../../services/content.service';



@Component({
  template: `
  <app-single *ngIf="item" [project]="item"></app-single>
  `
})
export class SinglePageComponent implements OnInit {

  item: any;

  constructor(private route: ActivatedRoute, private contentService: ContentService) { }

  ngOnInit(): void {
    this.item = this.route.snapshot.data.data;
  }



}
