import { DOCUMENT } from '@angular/common';
import { Inject, Injectable } from '@angular/core';
import * as d3 from 'd3';
import { Subject } from 'rxjs';

@Injectable()
export class DrawingPageService {

  svg: any;
  footerSvg: any;
  dataPath: any;
  height = window.innerWidth * (9/16);
  pathStr: string;
  swingCord = [];
  animating = false;
  aboutSVG: any;

  animateScroll: Subject<any> = new Subject();
  navLink: Subject<any> = new Subject();

  public circleAnimation: any;
  amplitudeX = window.innerWidth * 0.03;
  amplitudeY = window.innerHeight * 0.03;
  scrollOffset = 0;
  inc = 0.00;

  constructor(@Inject(DOCUMENT) private document: Document) {
    this.dataPath = [
      { type:'M', x: 30.46, y: -2.77 },
      { type:'S', x2: 25.01, y2: 8.65, x: 60.84, y: 8.2 },
      { type:'C', x1: 78.42, y1: 7.2, x2: 86.26, y2: 7.8, x: 92, y: 15.1 },
      { type:'C', x1: 92, y1: 15.1, x2: 95.7, y2: 19.2, x: 95.6, y: 25.6 },
      { type:'C', x1: 95.4, y1: 33.2, x2: 89.5, y2: 39.56, x: 81.5, y: 39.69 },
      { type:'C', x1: 73.7, y1: 39.69, x2: 67.45, y2: 33.2, x: 67.45, y: 25.6 },
      { type:'C', x1: 67.45, y1: 17.83, x2: 73.7, y2: 11.5, x: 81.54, y: 11.5 },
      { type:'S', x2: 98, y2: 19.16, x: 101.4, y: 34.6 }
    ];

  }
  public animateScrollPage(offset: number) {
    this.animateScroll.next(offset);
  }

  public link(hash: string) {
    this.navLink.next(hash);
  }

  draw() {
    const x = d3.scaleLinear().domain([0, 100]).range([0, window.innerWidth]);
    const y = d3.scaleLinear().domain([0, 100]).range([0, window.innerWidth]);

    d3.selectAll('#illustration').remove();

    this.svg = d3.select('#svgPage')
      .append('svg')
      .attr('width', window.innerWidth)
      .attr('height', window.innerWidth)
      .attr('id', 'illustration');

    this.pathStr = this.generatePathsString(x, y);

    const path = this.svg.append('path')
      .attr('d', this.pathStr)
      .attr('stroke', '#000')
      .attr('stroke-width', window.innerWidth * 0.015)
      .attr('fill', 'transparent')
      .attr('transform', 'translate(0,0) scale(1)');

    const totalLength = path.node().getTotalLength();

    path.attr('stroke-dasharray', totalLength + ' ' + totalLength )
      .attr('stroke-dashoffset', totalLength )
      .transition()
      .duration(1200)
      .ease(d3.easeLinear)
      .attr('stroke-dashoffset', 0);
  }

  generatePathsString(x: any, y: any) {
    let path = '';

    for (const el of this.dataPath) {
      if (el.type === 'C') {
        path += el.type + x(el.x1) + ',' + y(el.y1 - 3) + ',' + x(el.x2) + ',' + y(el.y2 - 3) + ',' + x(el.x) + ',' + y(el.y - 3);
      } else if (el.type === 'S') {
        path += el.type + x(el.x2) + ',' + y(el.y2 - 3) + ',' + x(el.x) + ',' + y(el.y - 3);
      } else if (el.type === 'M') {
        path += el.type + x(el.x) + ',' + y(el.y - 3);
      }
    }
    return path;
  }

  updateRadialBackground(x: number, y: number) {
    // d3.select('#mouseGradient').attr('cx', x).attr('cy', y - (window.innerWidth / 3));
  }

  setSwingCordVariables(y: any) {
    this.swingCord = [
      [
        [50, 0],
        [50.5, (y.invert(12))],
        [51.2, (y.invert(23))]
      ],
      [
        [50, 0],
        [50, 0],
        [50, (y.invert(30))]
      ],
      [
        [50, 0],
        [49.5, (y.invert(12))],
        [48.8, (y.invert(23))]
      ],
      [
        [50, 0],
        [50.2, (y.invert(10))],
        [50.8, (y.invert(25))]
      ],
      [
        [50, 0],
        [49.8, (y.invert(10))],
        [49.5, (y.invert(28))]
      ],
      [
        [50, 0],
        [46, (y.invert(15))],
        [50, (y.invert(30))]
      ]
    ];
  }

  drawFooter() {

    d3.selectAll('#svgFooter').remove();

    this.footerSvg =  d3.select('#footerSVG')
      .append('svg')
      .attr('width', window.innerWidth)
      .attr('height', window.innerHeight * 0.6)
      .attr('id', 'svgFooter');

    const x = d3.scaleLinear().domain([0, 100]).range([0, window.innerWidth]);
    const y = d3.scaleLinear().domain([0, 100]).range([0, window.innerWidth]);

    const line = d3.line()
      .x((d) => x(d[0]))
      .y((d) => y(d[1]))
      .curve(d3.curveNatural);

    this.setSwingCordVariables(y);

    const group = this.footerSvg.append('g')
      .attr('id', 'mainGroupPage')
      .attr('transform', 'translate(0, 0)');

    // const radialGradient = group.append('defs')
    //   .append('radialGradient')
    //   .attr('id', 'radial-gradient');

    // radialGradient.append('stop')
    //     .attr('offset', '20%')
    //     .attr('stop-color', '#fcf8f1');

    // radialGradient.append('stop')
    //   .attr('offset', '80%')
    //   .attr('stop-color', '#d4f0ff');

    // const mouseGradient = group.append('circle')
    //   .attr('id', 'mouseGradient')
    //   .attr('cx', window.innerWidth / 2)
    //   .attr('cy', window.innerHeight * 0.25)
    //   .attr('r', window.innerWidth / 3)
    //   .attr('fill', 'url(#radial-gradient)')
    //   .attr('pointer-events', 'none')
    //   .attr('opacity', 0.5);

    const cord = group.append('path')
      .attr('d', () => line(this.swingCord[1]))
      .attr('id', 'cord')
      .style('stroke', '#000')
      .style('stroke-width', 2)
      .style('fill', 'none');

      const dragRing = d3.drag()
      .subject((d) => { return d; })
      .on('start', () => {
        if (!this.document.getElementById('cursor').classList.contains('is-moving')) {
          this.document.getElementById('cursor').classList.add('is-moving');
        }
        this.fixFooterBg();
      })
      .on('drag', (d) =>  {
        if (!this.animating && d.sourceEvent.y > y(26)) {
          const yStartPos = 30 + (window.innerWidth * 0.01);
          const diff = d.y - yStartPos;

          if (diff < 15) {
            this.swingCord[1] = [
              [50, 0],
              [50, 0],
              [50, (y.invert(30 + diff))]
            ];
          } else {
            this.swingCord[1] = [
              [50, (y.invert(diff - 15))],
              [50, (y.invert(diff - 15))],
              [50, (y.invert(30 + diff))]
            ];

            this.document.getElementById('page-footer').style.paddingTop = (diff - 15) + 'px';

          }
          d3.select('#cord').attr('d', line(this.swingCord[1]));
          d3.select('#ring').attr('cy', (30 + (window.innerWidth * 0.01) + diff));
        }
        this.document.getElementById('cursor').style.top = d.sourceEvent.y + 'px';
        this.document.getElementById('cursor').style.left = d.sourceEvent.x + 'px';
        if (!this.document.getElementById('cursor').classList.contains('is-moving')) {
          this.document.getElementById('cursor').classList.add('is-moving');
        }
      })
      .on('end', (d) => {


        if (this.document.getElementById('cursor').classList.contains('is-moving')) {
          this.document.getElementById('cursor').classList.remove('is-moving');
        }

        this.swingCord[1] = [
          [50, 0],
          [50, 0],
          [50, (y.invert(30))]
        ];
        this.document.getElementById('page-footer').classList.add('transitionStyle');
        d3.select('#cord').transition().ease(d3.easeLinear).duration(280).attr('d', line(this.swingCord[1]));
        d3.select('#ring').transition().ease(d3.easeLinear).duration(280).attr('cy', 30 + (window.innerWidth * 0.01));
        this.document.getElementById('footerBG').style.marginTop = '0px';
        this.document.getElementById('page-footer').style.marginTop = (window.innerHeight * -0.3) + 'px';
        const offsetTop = this.document.getElementById('footerBG').scrollTop + (window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop || 0);
        this.unFixFooterBg();

      });


    const ring = group.append('circle')
      .attr('id', 'ring')
      .attr('r', window.innerWidth * 0.01)
      .attr('cx', window.innerWidth * 0.5)
      .attr('cy', 30 + (window.innerWidth * 0.01))
      .attr('stroke', '#000')
      .attr('stroke-width', window.innerWidth * 0.0045)
      .attr('fill', 'transparent')
      .attr('cursor', 'grab')
      .attr('shape-rendering', 'geometricPrecision')
      .call(dragRing);

  }


  resetFooterBg() {
    this.document.getElementById('footerBG').style.position = 'absolute';
    this.document.getElementById('footerBG').style.marginTop = '0px';
  }

  fixFooterBg() {
    const offsetTop = this.document.getElementById('footerBG').getBoundingClientRect().top;
    this.document.getElementById('footerBG').style.marginTop = offsetTop + 'px';
    this.document.getElementById('footerBG').style.position = 'fixed';

  }

  unFixFooterBg() {
    this.document.getElementById('footerBG').style.marginTop = '0px';
    this.document.getElementById('footerBG').style.position = 'absolute';
    this.document.getElementById('page-footer').style.paddingTop = '0px';
    this.document.getElementById('page-footer').style.marginTop =  (window.innerHeight * 0.1) + 'px';
    this.document.getElementById('page-footer').classList.remove('transitionStyle');
    this.drawFooter();
    window.scrollTo({
      top: 0,
      behavior: 'smooth'
    });
  }



  startCircleAnimation() {
    this.circleAnimation = setInterval(() => this.moveCircles(), 20);
  }

  moveCircles() {

    const valueX = Math.sin(this.inc) * this.amplitudeX;
    const valueY = Math.sin(this.inc) * this.amplitudeY;

    this.aboutSVG.select('#blueCircle').transition().ease(d3.easeLinear)
      .attr('cx', (window.innerWidth * 0.62) + valueX)
      .attr('cy', () => this.scrollOffset === 0 ? (window.innerHeight * 0.40) + (valueY / 2) : (window.innerHeight * 0.40) - (this.scrollOffset * 0.5));

    this.aboutSVG.select('#circleOutline').transition().ease(d3.easeLinear)
      .attr('cx', (window.innerWidth * 0.48) - (valueX / 1.5))
      .attr('cy', () => this.scrollOffset === 0 ? (window.innerHeight * 0.30) - (valueY / 3) : (window.innerHeight * 0.30) + (this.scrollOffset * 0.5));
    this.inc += 0.01;
  }

  stopAnimation() {
    if (this.circleAnimation) {
      clearInterval(this.circleAnimation);
    }
    this.inc = 0.0;
  }

  updateAmplitude(x: number, y: number, scrollOffset: number) {
    this.amplitudeX = window.innerWidth * 0.03 * x;
    this.amplitudeY = window.innerHeight * 0.03 * y;
    this.scrollOffset = scrollOffset;
  }


  drawAbout() {

    this.aboutSVG = d3.select('#aboutSVG')
      .append('svg')
      .attr('width', window.innerWidth)
      .attr('height', window.innerHeight)
      .attr('id', 'svgAbout');

    const circle = this.aboutSVG.append('circle')
      .attr('id', 'blueCircle')
      .attr('class', 'circleElement')
      .attr('cx', window.innerWidth * 0.62)
      .attr('cy', window.innerHeight * 0.40)
      .attr('r', window.innerHeight * 0.2)
      .style('fill', '#A836FF');

    // const circle2 = this.aboutSVG.append('circle')
    //   .attr('cx', window.innerWidth * 0.68)
    //   .attr('cy', window.innerHeight * 0.55)
    //   .attr('r', window.innerHeight * 0.08)
    //   .style('fill', '#79C3E6')
    //   .style('mix-blend-mode', 'color-dodge');

    const circle3 = this.aboutSVG.append('circle')
      .attr('id', 'circleOutline')
      .attr('class', 'circleElement')
      .attr('cx', window.innerWidth * 0.48)
      .attr('cy', window.innerHeight * 0.3)
      .attr('r', window.innerHeight * 0.16)
      .style('fill', 'none')
      .style('stroke', '#E6FF00')
      .style('stroke-width', window.innerWidth * 0.002)
      .style('mix-blend-mode', 'exclusion');

    // this.aboutSVG.selectAll('#blueCircle').transition().ease(d3.easeLinear).duration(1000).attr('r', window.innerHeight * 0.2);
    // this.aboutSVG.selectAll('#circleOutline').transition().ease(d3.easeLinear).duration(800).attr('r', window.innerHeight * 0.16);



  }
}


