import { DOCUMENT } from '@angular/common';
import { Inject, Injectable } from '@angular/core';


@Injectable()
export class CursorService {

  timer: any;

  constructor(@Inject(DOCUMENT) private document: Document) {}

  moveCursor(x: number, y: number) {
    if (!this.document.getElementById('cursor').classList.contains('is-moving')) {
      this.document.getElementById('cursor').classList.add('is-moving');
    }

    this.document.getElementById('cursor').style.top = y + 'px';
    this.document.getElementById('cursor').style.left = x + 'px';

    clearTimeout(this.timer);

    this.timer = setTimeout(() => {
      if (this.document.getElementById('cursor') && this.document.getElementById('cursor').classList.contains('is-moving')) {
        this.document.getElementById('cursor').classList.remove('is-moving');
      }
    }, 250);
  }

  moveImage(x: number, y: number) {

    this.document.getElementById('image-cursor').style.top = y + 'px';
    this.document.getElementById('image-cursor').style.left = (x + (window.innerWidth * 0.1)) + 'px';
  }

  showCursorHighlight() {
    this.document.getElementById('cursor').classList.add('highlight');
  }

  hideCursorHighlight() {
    this.document.getElementById('cursor').classList.remove('highlight');
  }

}
