import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { WorkComponent } from './components/work/work.component';
import { SinglePageComponent } from './components/single-page/single-page.component';
import { AboutPageComponent } from './components/about/about-page.component';
// import { ResearchPageComponent } from './components/research/research-page.component';
import { ResolverService } from './services/resolver.service';
import { PageResolverService } from './services/page-resolver.service';


const routes: Routes = [
    { path: '', component: WorkComponent, resolve: { data: ResolverService }, pathMatch: 'full'},
    { path: 'about', component: AboutPageComponent, pathMatch: 'full'},
    // { path: 'research', component: ResearchPageComponent, pathMatch: 'full'},
    { path: ':id', component: SinglePageComponent, resolve: { data: PageResolverService }, pathMatch: 'prefix'}
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
