import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';

@Component({
  template: `<app-about></app-about>`
})
export class AboutPageComponent implements OnInit {


  constructor(private route: ActivatedRoute) {
    console.log('aboutPage');
  }

  ngOnInit(): void {

  }
}
