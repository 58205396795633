import { Component, OnInit, Input, Inject, HostListener, AfterViewInit } from '@angular/core';
import { ContentService } from '../../services/content.service';
import { Project } from '../../models/data.model';
import { Router } from '@angular/router';
import { CursorService } from 'src/app/services/cursor.service';
import { DOCUMENT } from '@angular/common';
import { DrawingPageService } from 'src/app/services/drawing-page.service';
import { PageService } from 'src/app/services/page.service';

@Component({
  selector: 'app-single',
  templateUrl: './single.component.html',
  styleUrls: ['./single.component.css']
})
export class SingleComponent implements OnInit, AfterViewInit {
  @Input() project: Project;


  activeImage = 0;
  projectImagesWidth = 0;
  disableScroll = false;
  scrollLeft = 0;
  scrollLeftFooter = 0;
  scrollOffsetImageContainer = window.innerHeight;
  footerOffsetContainer = window.innerHeight * 2;
  currentScrollPosY = 0;

  constructor(@Inject(DOCUMENT) private document: Document, private cursorService: CursorService,
              private drawingPageService: DrawingPageService, private router: Router, private contentService: ContentService,
              private pageService: PageService) {

    this.drawingPageService.animateScroll.subscribe(res => {
      this.animateScrollPage(res);
    });

    this.drawingPageService.navLink.subscribe(res => {
      this.link(res);
    });
  }


  @HostListener('document:mousemove', ['$event'])
  onMouseMove(e: MouseEvent) {

    // const mouseXpercentage = Math.round(e.pageX / window.innerWidth * 100);
    // const mouseYpercentage = Math.round(e.pageY / window.innerHeight * 100);
    // if (this.document.scr)
    // if ((window.innerHeight + window.scrollY) >= document.body.offsetHeight) {
      // you're at the bottom of the page
    //   this.drawingPageService.updateRadialBackground(e.pageX, e.offsetY);
    // }


    // this.document.getElementById('background').style.background = 'radial-gradient(at ' + mouseXpercentage + '% ' + mouseYpercentage + '%,  #fff4df, #e4edf2, #def3ff)';
    if(!/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)){
      // true for mobile device
      this.cursorService.moveCursor(e.pageX, e.pageY);
    }
  }


  // @HostListener('window:scroll', ['$event'])
  // onWindowScroll(e) {

  //   // if (window.pageYOffset > this.currentScrollPosY) {

  //   // }
  //   // this.currentScrollPosY = window.pageYOffset;

  // }

  @HostListener('mousewheel', ['$event'])
  onMousewheel(e) {

    if (window.pageYOffset + (2*e.deltaY) >= this.scrollOffsetImageContainer && e.deltaY > 0 && this.scrollLeft < this.projectImagesWidth - window.innerWidth) {
      window.scrollTo({top: this.scrollOffsetImageContainer });
      this.document.getElementsByTagName('BODY')[0].classList.add('disable-scroll');
      this.disableScroll = true;
    } else if (window.pageYOffset + (2*e.deltaY) >= this.footerOffsetContainer && e.deltaY > 0 && this.scrollLeftFooter < window.innerWidth * 0.7) {
      window.scrollTo({top: this.footerOffsetContainer });
      this.document.getElementsByTagName('BODY')[0].classList.add('disable-scroll');
      this.disableScroll = true;
    }


    if (this.disableScroll && e.deltaY < 0 && this.scrollLeft <= 0 && window.pageYOffset <= this.scrollOffsetImageContainer) {
      this.disableScroll = false;
      this.scrollLeft = 0;
      this.document.getElementById('project-images-container').scrollLeft = this.scrollLeft;
      this.enableScroll();
    } else if (this.disableScroll && e.deltaY < 0 && this.scrollLeftFooter <= 0 && window.pageYOffset <= this.footerOffsetContainer) {
      this.disableScroll = false;
      this.scrollLeftFooter = 0;
      this.document.getElementById('page-footer').scrollLeft = this.scrollLeftFooter;
      this.enableScroll();
    } else if (this.disableScroll) {

      if (window.pageYOffset < this.footerOffsetContainer - window.innerHeight) {
        this.scrollLeft += (e.deltaY * 2);

        if (this.scrollLeft >= this.projectImagesWidth - window.innerWidth + 300) {
          this.disableScroll = false;
          this.enableScroll();
        } else {
          // window.scrollTo(0, this.scrollOffsetImageContainer);
          this.document.getElementById('project-images-container').scrollLeft = this.scrollLeft;

          if (this.scrollLeft < this.projectImagesWidth - window.innerWidth - window.innerHeight && this.scrollLeft > window.innerHeight * 1.5) {
            this.document.getElementById('cursor').classList.add('imageHover');
          } else {
            if (this.document.getElementById('cursor').classList.contains('imageHover')) {
              this.document.getElementById('cursor').classList.remove('imageHover');
            }
          }
        }
      } else {
        this.scrollLeftFooter += e.deltaY;

        if (this.scrollLeftFooter >= window.innerWidth * 0.7) {
          this.disableScroll = false;
          this.enableScroll();
        } else {

          this.document.getElementById('horizontal-container').scrollLeft = this.scrollLeftFooter;
        }
      }
    }
  }


  enableScroll() {
    this.document.getElementsByTagName('BODY')[0].classList.remove('disable-scroll');
    this.document.getElementById('cursor').classList.remove('imageHover');
  }

  ngOnInit(): void {
    setTimeout(() => {
      this.drawingPageService.draw();
      this.drawingPageService.drawFooter();
    }, 300);
    window.scrollTo(0,0);
    this.scrollLeft = 0;
    this.scrollLeftFooter = 0;
    this.disableScroll = false;
    this.enableScroll();
  }

  ngAfterViewInit() {
    setTimeout(() => {
      this.projectImagesWidth = this.document.getElementById('project-images').getBoundingClientRect().width;
      this.scrollOffsetImageContainer = this.document.getElementById('project-images-container').getBoundingClientRect().top + (window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop || 0);
      this.footerOffsetContainer = this.document.getElementById('page-footer').getBoundingClientRect().top + (window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop || 0);
    }, 300);

    setTimeout(() => {
      this.scrollOffsetImageContainer = this.document.getElementById('project-images-container').getBoundingClientRect().top + (window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop || 0);
      this.footerOffsetContainer = this.document.getElementById('page-footer').getBoundingClientRect().top + (window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop || 0);
    }, 1000);
  }

  playVideo() {
    window.navigator.vibrate(300);
    this.document.getElementById('play-button-bg').classList.add('hide');
    this.document.getElementById('play-button').classList.add('hide');

    (this.document.getElementById('video') as HTMLVideoElement).play();
    (this.document.getElementById('video') as HTMLVideoElement).addEventListener('ended', () => {
      this.showControls();
    }, false);
  }

  showControls() {
    setTimeout(() => {
      this.document.getElementById('play-button-bg').classList.remove('hide');
      this.document.getElementById('play-button').classList.remove('hide');
    }, 300);
  }

  resetScrollOffset() {
    this.scrollLeft = 0;
    window.scrollTo(0, this.scrollOffsetImageContainer);
    this.document.getElementById('project-images-container').scroll({
      left: 0,
      behavior: 'smooth'
    });
  }

  downloadDocument(url: string) {
    window.navigator.vibrate(300);
    window.open(url, '_blank');
  }


  animateScrollPage(offset: number) {
    this.document.getElementById('wrapper').scroll({
      top: offset,
      behavior: 'smooth'
    });
  }

  navigateTo(direction: number) {
    let projects = [];
    window.navigator.vibrate(300);
    this.contentService.getData().subscribe(res => {
      projects = res['projects'];
      if (this.project.id + direction > 0 && this.project.id + direction <= projects.length) {
        const page = projects.filter(p => p.id === this.project.id + direction)[0];
        this.pageService.setActivePage(page.id);
        window.location.href = window.location.origin + '/' + page.slug;
      }
    });

  }

  link(link: string, scrollTop = false) {
    window.navigator.vibrate(300);
    if (scrollTop) { window.scrollTo(0,0); }
    this.router.navigate(['/', link]);
  }

}
