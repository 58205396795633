<div class="wrapper" #wrapper id="wrapper">

  <!-- <div id="background" class="radial-gradient"></div> -->

  <div id="svg" class="mobile-hidden"></div>

  <div class="nav">
    <div class="navigation left active" id="left-button" (mouseenter)="this.cursorService.showCursorHighlight()" (mouseleave)="this.cursorService.hideCursorHighlight()" (click)="link('/')"><span></span>my work</div>
    <div class="navigation right" id="right-button" (mouseenter)="this.cursorService.showCursorHighlight()" (mouseleave)="this.cursorService.hideCursorHighlight()" (click)="link('about')"><span></span>about me</div>
    <div class="mobile-nav"></div>
  </div>

  <div class="intro-text">
    <h1>Hi!</h1>
    <p>My name is Anke.<br> I am a designer and researcher passionate about creating aesthetic interactions with digital and physical products.</p>
  </div>

  <div *ngFor="let d of this.data">
    <div class="column left" [hidden]="d?.id !== this.drawingService.activeSection">
      <div class="teaser-image">
          <img src="{{ d?.teaserImage }}">
      </div>
    </div>
  </div>
  <div *ngFor="let d of this.data; let i = index">
    <div class="column right" [hidden]="d?.id !== this.drawingService.activeSection">
      <div class="project-details">
        <div class="project-title">{{ d?.title }}</div>
        <div class="project-subtitle">{{ d?.description }}</div>
        <div class="publication" *ngFor="let publication of d?.publications"> {{ publication?.conference }} {{ publication?.awards }}</div>
        <a class="learn-more" (click)="viewProject(d?.slug, i)">Learn more <div class="arrow-link"></div></a>
      </div>
    </div>
  </div>

  <div id="cursor"></div>
</div>

