import { Component, Inject, OnInit, HostListener } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ConfigService } from 'src/app/services/config.service';
import { DOCUMENT } from '@angular/common';
import { DrawingService } from 'src/app/services/drawing.service';
import { CursorService } from 'src/app/services/cursor.service';
import { PageService } from 'src/app/services/page.service';

@Component({
  selector: 'app-work',
  templateUrl: './work.component.html',
  styleUrls: ['./work.component.css']
})
export class WorkComponent implements OnInit {

  data = [];

  config: any;
  last: number = null;
  scrollDistance = 0;
  lastScrollDistance = 0;

  constructor(@Inject(DOCUMENT) private document: Document, private router: Router, public drawingService: DrawingService,
              private configService: ConfigService, public cursorService: CursorService, private pageService: PageService,
              private activedRoute: ActivatedRoute) {

    this.config = this.configService.config;

    this.drawingService.animateScroll.subscribe(res => {
      this.animateScrollPage(res);
    });

    this.drawingService.getProjectList.subscribe(res => {
      this.drawingService.projects = this.data;
    });

    this.drawingService.route.subscribe(res => {
      this.link(res);
    });
  }

  ngOnInit(): void {
    const page = this.pageService.getActivePage();

    this.activedRoute.data.subscribe(d => {
      this.data = d.data.projects;
      this.drawingService.projects = d.data.projects;
      this.drawingService.activeSection = page;
      if (page > 0) {

        setTimeout(() => {
          this.drawingService.redraw();
          this.document.getElementById('wrapper').scrollTo(0, window.innerHeight);
          this.drawingService.showPage(page);
        }, 500);
      } else {
        setTimeout(() => {
          this.document.getElementById('wrapper').scrollTo(0, 0);
          this.drawingService.draw();
          this.drawingService.drawTimeLineLabels();
        }, 500);
      }
    });
  }



  @HostListener('mousewheel', ['$event'])
  onMousewheel(e) {
    this.scrollFunction(e);
  }

  scrollFunction(e: any) {
    if (this.drawingService.animating === false) {
      this.scrollDistance += e.deltaY;
      if (e.deltaY > 10 && this.document.getElementById('wrapper').scrollTop === 0) {
        this.drawingService.updateScrollLine(1);
        this.animateScrollPage(window.innerHeight);
        this.lastScrollDistance = this.scrollDistance;
      } else if (e.deltaY < -10 && this.document.getElementById('wrapper').scrollTop > 0) {
        if (this.scrollDistance - this.lastScrollDistance < -400) {
          if (this.drawingService.activeSection > 0) {
            this.drawingService.updateScrollLine(this.drawingService.activeSection - 1);
          }
          if (this.drawingService.activeSection <= 0) {
            this.animateScrollPage(0);
          }
          this.lastScrollDistance = this.scrollDistance;
        }
      } else if (e.deltaY > 10 && this.document.getElementById('wrapper').scrollTop > 0) {
        if (this.scrollDistance - this.lastScrollDistance > 400) {
          if (this.drawingService.activeSection < 4) {
            this.drawingService.updateScrollLine(this.drawingService.activeSection + 1);
          }
          this.lastScrollDistance = this.scrollDistance;
        }
      }
    }
  }


  @HostListener('document:mousemove', ['$event'])
  onMouseMove(e: MouseEvent) {

    // const mouseXpercentage = Math.round(e.pageX / window.innerWidth * 100);
    // const mouseYpercentage = Math.round(e.pageY / window.innerHeight * 100);

    // this.drawingService.updateRadialBackground(e.pageX, e.offsetY);
    // this.document.getElementById('background').style.background = 'radial-gradient(at ' + mouseXpercentage + '% ' + mouseYpercentage + '%, #fff4df, #dbdfe1, #c9cdd0)';

    this.cursorService.moveCursor(e.pageX, e.pageY);
  }

  animateScrollPage(offset: number) {
    this.document.getElementById('wrapper').scroll({
      top: offset,
      behavior: 'smooth'
    });
  }

  viewProject(projectSlug: string, index: number) {
    this.drawingService.hidePage((index + 1));
    this.pageService.setActivePage(index + 1);
    window.navigator.vibrate(300);
    setTimeout(() => {
      this.drawingService.removeClippath(index);
      this.router.navigate(['/' + projectSlug]);
    }, 300);
  }



  // @HostListener('window:scroll', [])
  // onWindowScroll() {

  //   if (window.pageYOffset > 0) {
  //     d3.select('#scrollLine').attr('y2', window.innerHeight + window.pageYOffset );
  //   }

  //   console.log(window.pageYOffset);
  // }


  @HostListener('window:resize', [])
  onWindowResize() {
    this.drawingService.redraw();
  }





  link(link: string) {
    this.pageService.setActivePage(0);
    this.router.navigate(['', link]);
  }

}
